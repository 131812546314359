import React from "react";
import {useTranslation} from "react-i18next";
import './info.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";


export const InfoBox = () => {

  const {t, i18n} = useTranslation();

  return (
    <>
      <div className="row p-5 contact">
        <div className="col-md-4 mt-5 mt-md-0">
          <h4 className="text-white-50">{t('open_hours_title')}</h4>
          <p className="text-white open-hours">
            {t('open_hours')}
          </p>
        </div>
        <div className="col-md-4 contact mt-5 mt-md-0">
          <h4 className="text-white-50">{t('contact')}</h4>
          <p className="m-1">
            <a href="tel:+493032799799" className="text-white">+49 (30) 32799 799</a>
          </p>
          <p className="m-1">
            <a href="mailto:info@restaurant-eli.de" className="text-white">info@restaurant-eli.de</a>
          </p>
          <div className="social text-center mt-3">
            <a href="https://www.facebook.com/Restaurant-Eli-111486898073287" target="_blank"
               className="text-white"><FontAwesomeIcon icon={faFacebookSquare}/></a>
            <a href="https://www.instagram.com/restauranteliberlin" target="_blank"
               className="text-white"><FontAwesomeIcon icon={faInstagramSquare}/></a>
          </div>

        </div>
        <div className="col-md-4 mt-5 mt-md-1 mb-5 mb-md-0">
          <h4 className="text-white-50">{t('address')}</h4>
          <a href="https://goo.gl/maps/aWyu6BRY33uYHy4s8" target="_blank">
            <div>
              <div>Sybelstrasse 33</div>
              <div>10629 Berlin</div>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}