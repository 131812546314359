import React from "react";
import {useTranslation} from 'react-i18next';


export const RenderMenuItems = (cat: string, prefix: string, nr: number) => {
  const {t, i18n} = useTranslation();

  let items = [];


  for (let item_nr = 1; item_nr <= nr; item_nr++) {
    let outer_id = prefix + ".item" + item_nr;
    let inner_id = prefix + ".item" + item_nr + "_heading";
    let title = t(prefix + ".item" + item_nr + "_title");
    let price = t(prefix + ".item" + item_nr + "_price");
    let desc = t(prefix + ".item" + item_nr + "_desc");

    items.push(
      <li className="list-group-item" key={outer_id}>
        <div className="item-heading lead d-flex justify-content-between">
          <div className="item-title">{title}</div>
          <div className="item-price">{price}&euro;</div>
        </div>
        <div className="text-muted"><small>{desc}</small></div>
      </li>

    )
  }
  return items;
}