import './App.css';
import {MainMenu} from "./header/mainmenu/mainmenu";
import {Main} from "./section/main/main";
import {AboutUs} from "./section/about/about";
import {Food} from "./section/menu/food/food";
import {Footer} from "./footer/footer";
import React, {Suspense} from 'react';
import {ComingSoon} from "./tmp/coming_soon";
import {Drinks} from "./section/menu/drinks/drinks";


function App() {
  return (
    <>
      <Suspense fallback="loading">
        {/*<ComingSoon />*/}
        <MainMenu/>
        <Main/>
        <div className="content container mb-5 mt-5">
          {/*<div className="row">*/}
          {/*  <AboutUs/>*/}
          {/*</div>*/}
          <div className="row">
            <Food/>
          </div>
          {/*<div className="row">*/}
          {/*  <Drinks/>*/}
          {/*</div>*/}
        </div>
        <Footer/>
      </Suspense>
    </>
  );
}

export default App;
