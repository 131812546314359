import React from "react";
import {useEffect} from 'react';
import logo from '../../logo.png';
import './mainmenu.css'
import {useTranslation} from 'react-i18next';
import {Language} from "../lang/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-regular-svg-icons";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";


interface MenuItem {
    href: string,
    text: string
}

export const MainMenu = () => {

    const {t, i18n} = useTranslation();

    useEffect(() => {

        let widget = document.getElementById('quandoo-booking-widget')
        if (widget != null) {
            widget.innerHTML = ""
        }

        const script = document.createElement('script');
        script.src = "https://booking-widget.quandoo.com/index.js";
        script.setAttribute('data-merchant-id', '89357')
        script.setAttribute('data-theme', 'brand')
        script.setAttribute('data-lang', i18n.language)
        script.async = false

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [i18n.language]);

    const menuItems: MenuItem[] = [
        {
            href: "#",
            text: t('home'),
        },
        // {
        //   href: "#about-us",
        //   text: t('about'),
        // },
        {
            href: "#menu",
            text: t('menu'),
        },
        {
            href: "#contact",
            text: t('contact'),
        }
    ]

    let pdf = process.env.PUBLIC_URL;
    if (i18n.language == "en") {
        pdf += "/eli_menu.pdf";
    } else {
        pdf += "/eli_speisekarte.pdf";
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light navbar-light fixed-top text-dark" id="top-navbar">
                <div className="container-md">
                    <a className="navbar-brand me-0 me-md-4" href="#">
                        <img src={logo} height="40" className="d-inline-block align-top" alt="logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#top-menu"
                            aria-controls="top-navbar"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="top-menu">
                        <ul className="nav navbar-nav me-auto mb-2 mb-lg-0 ri navbar-nav-scroll">
                            {menuItems.map(item =>
                                <li className="nav-item" key={"item-" + item.href}>
                                    <a className="nav-link" href={item.href}>{item.text}</a>
                                </li>
                            )}
                            <li className="nav-item">
                                <a href="#" data-bs-toggle="modal"
                                   className="nav-link"
                                   data-bs-target="#impressumModal">Impressum</a>
                            </li>
                            <li className="nav-item disabled">
                                <a className="nav-link">|</a>
                            </li>
                            <li className="nav-item" key="item-lang">
                                <a className="nav-link"><Language/></a>
                            </li>
                        </ul>

                        <a href={pdf} className="menu-pdf-download" target="_blank">
                            <button className="btn btn-outline-dark book-table-button" type="button">
                                <FontAwesomeIcon className="me-1" icon={faFileDownload}/> {t('menu')}</button>
                        </a>
                        <a href="#" data-bs-toggle="modal" className="nav-link mx-3" data-bs-target="#bookTableModal">
                            <button className="btn btn-outline-dark book-table-button"
                                    type="button">{t('book')}</button>
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
};


