import React, {useEffect, useState} from "react";
import './main.css'
import {InfoBox} from "../../shared/info/info";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";


export const Main = () => {

  const {t, i18n} = useTranslation();
  const [hideHeaderContact, setHideHeaderContact] = useState(window.pageYOffset !== 0)

  function onScroll() {
    setHideHeaderContact(window.pageYOffset !== 0)
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function getStyle() {
    return {
      opacity: hideHeaderContact ? "0" : "1",
      zIndex: hideHeaderContact ? -1 : 1
    }
  }

  let pdf = process.env.PUBLIC_URL;
  if (i18n.language == "en") {
    pdf += "/eli_menu.pdf";
  } else {
    pdf += "/eli_speisekarte.pdf";
  }

  return (
    <>
      <header className="mb-auto main-section d-flex justify-content-center align-items-center">

        <div className="align-items-center justify-content-center">

          <div>
            <a href={pdf} className="menu-pdf-download" target="_blank">
              <button className="btn btn-outline-light book-table-button w-100" type="button">
                <FontAwesomeIcon className="me-1" icon={faFileDownload}/> {t('menu')}</button>
            </a>
            <a href="#" data-bs-toggle="modal" className="nav-link mt-3" data-bs-target="#bookTableModal">
              <button className="btn btn-outline-light book-table-button w-100"
                      type="button">{t('book')}</button>
            </a>
          </div>
        </div>

        <div style={getStyle()} className="container-fluid text-center text-white fixed-bottom contact-info">
          <div className="container">
            <div className="row">
              <div className="container-md">
                <InfoBox/>
              </div>
            </div>
          </div>
        </div>

      </header>
    </>
  );
};
