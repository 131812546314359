import React from "react";
import './food.css'
import {foodItems, MenuItems} from "../items";
import {useTranslation} from 'react-i18next';
import {RenderMenuItems} from "../util";

export const Food = () => {

  const {t, i18n} = useTranslation();


  return (
    <>
      <section className="menu mb-5" id="menu">
        <div className="container-md">
          <div className="row mb-4">
            <div className="col-md-12 d-flex justify-content-center"><h3
              className="text-center text-dark section-title">{t('menu')}</h3></div>
          </div>
          <div className="row">
            <div className="offset-md-1 col-md-10">
              <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                {foodItems.map((cat: MenuItems, idx: number) => {
                    const active = idx === 0 ? "active" : ""

                    return <li className="nav-item" role="presentation" key={cat.id}>
                      <button className={active + " nav-link lead"} id="pills-home-tab" data-bs-toggle="pill"
                              data-bs-target={"#" + cat.id} type="button" role="tab"
                              aria-controls="pills-home" aria-selected={idx === 0}>{t(cat.prefix + ".title")}
                      </button>
                    </li>
                  }
                )}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {foodItems.map((cat: MenuItems, idx: number) => {
                    const active = idx === 0 ? "active" : ""
                    const show = idx === 0 ? "show" : "fade"

                    return <div className={active + " tab-pane " + show} id={cat.id} role="tabpanel"
                                aria-labelledby="pills-home-tab" key={"div" + cat.id}>
                      <div className="accordion accordion-flush" id={cat.id + "items"}>
                        <ul className="list-group list-group-flush">
                          {RenderMenuItems(cat.id, cat.prefix, cat.items)}
                        </ul>
                      </div>
                    </div>
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}