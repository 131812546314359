import React from "react";
import {useTranslation} from "react-i18next";
import './lang.css'


export const Language = () => {

  const {t, i18n} = useTranslation();

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang)
  }

  const languages = [
    "en", "de"
  ]

  return (
    <>
      <div className="language">
        {languages.map(lang => {
          const active = lang === i18n.language ? "active" : "passive"
          return <button onClick={() => changeLanguage(lang)} className={active} key={lang}>{lang}</button>
        })}
      </div>
    </>
  )
}